import gql from 'graphql-tag';

export const HideProduct = gql`
  mutation HideProduct(
    $productId: String!
  ) {
    hideProduct(productId: $productId)
  }
`;

export const UnhideProduct = gql`
  mutation UnhideProduct(
    $productId: String!
  ) {
    unhideProduct(productId: $productId)
  }
`;