import gql from 'graphql-tag';

export const DeleteVideo = gql`
  mutation DeleteVideo(
    $video: String!
  ) {
    adminUpdateVideo(
      video: {
        id: $video,
        visible: false
      }
    ) {
      id
    }
  }
`;

export const ResurrectVideo = gql`
  mutation ResurrectVideo(
    $video: String!
  ) {
    adminUpdateVideo(
      video: {
        id: $video,
        visible: true
      }
    ) {
      id
    }
  }
`;

export const FeatureVideo = gql`
  mutation FeatureVideo(
    $video: String!
  ) {
    adminUpdateVideo(
      video: {
        id: $video,
        featured: true
      }
    ) {
      id
    }
  }
`;

export const UnfeatureVideo = gql`
  mutation UnfeatureVideo(
    $video: String!
  ) {
    adminUpdateVideo(
      video: {
        id: $video,
        featured: false
      }
    ) {
      id
    }
  }
`;

export const ChangeVideoName = gql`
  mutation UnfeatureVideo(
    $video: String!,
    $name: String!
  ) {
    adminUpdateVideo(
      video: {
        id: $video,
        name: $name
      }
    ) {
      id
    }
  }
`;
