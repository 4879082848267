import AccountHelper from '../../helpers/account';

export const setId = (id) => ({
  namespace: 'account',
  type: 'SET_ID',
  id
});

export const setName = (name) => ({
  namespace: 'account',
  type: 'SET_NAME',
  name
});

export const setUsername = (username) => ({
  namespace: 'account',
  type: 'SET_USERNAME',
  username
});

export const setPicture = (picture) => ({
  namespace: 'account',
  type: 'SET_PICTURE',
  picture
});

export const setEmail = (email) => ({
  namespace: 'account',
  type: 'SET_EMAIL',
  email
});

export const setRoles = (roles) => ({
  namespace: 'account',
  type: 'SET_ROLES',
  roles
});

export const setFeatures = (features) => ({
  namespace: 'account',
  type: 'SET_FEATURES',
  features
});

export const setIsLoggedIn = (isLoggedIn) => ({
  namespace: 'account',
  type: 'SET_LOGGED_IN',
  isLoggedIn
});

export const setIsAccountLoaded = (isAccountLoaded) => ({
  namespace: 'account',
  type: 'SET_ACCOUNT_LOADED',
  isAccountLoaded
});

export const setIsLoggingIn = (isLoggingIn) => ({
  namespace: 'account',
  type: 'SET_LOGGING_IN',
  isLoggingIn
});

export const loginWithCredentials = ({ username, password }) => (
  async (dispatch) => {
    dispatch(setIsLoggingIn(true));
    try {
      const { idToken, accessToken } = await AccountHelper.loginWithCredentials(username, password);
      await AccountHelper.saveSession(idToken, accessToken);
      const user = AccountHelper.getUserFromSession();

      if (user.roles && user.roles.includes('admin')) {
        dispatch(loginWithSavedSession());
      } else {
        window.localStorage.removeItem('zipline_access_token');
        window.localStorage.removeItem('zipline_id_token');
        throw new Error('Invalid permissions');
      }
    } catch (err) {
      dispatch(setIsLoggingIn(false));
      if(!err.message) throw new Error('Invalid credentials');
      throw err;
    }
  }
);

export const loginWithSavedSession = () => (
  async (dispatch) => {
    dispatch(setIsLoggingIn(true));
    const user = AccountHelper.getUserFromSession();

    if (user) {
      dispatch(setId(user.id));
      dispatch(setName(user.name));
      dispatch(setUsername(user.username));
      dispatch(setPicture(user.picture));
      dispatch(setEmail(user.email));
      dispatch(setIsLoggedIn(user.isLoggedIn));
      dispatch(setRoles(user.roles));
      dispatch(setFeatures(user.features));
      dispatch(setIsAccountLoaded(true));
      dispatch(setIsLoggingIn(false));
    } else {
      dispatch(setIsLoggedIn(false));
      dispatch(setIsAccountLoaded(true));
      dispatch(setIsLoggingIn(false));
    }
  }
);

export const logout = () => (
  async () => {
    window.localStorage.removeItem('zipline_access_token');
    window.localStorage.removeItem('zipline_id_token');
    window.location.reload();
  }
);
