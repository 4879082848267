import { compose, withStateHandlers, withHandlers, getContext } from 'recompose';
import PropTypes from 'prop-types';

import csv from 'papaparse';

import { graphql } from 'react-apollo';
import { CreateVariant } from './mutations';

import CreateVariants from './CreateVariants';

export default compose(
  getContext(
    { mixpanel: PropTypes.object }
  ),

  graphql(
    CreateVariant,
    { name: 'createVariant' }
  ),

  withStateHandlers(
    {
      headers: [],
      variants: [],
      loading: false
    },
    {
      onHeadersChange: () => ({ headers }) => ({ headers }),
      onVariantsChange: () => ({ variants }) => ({ variants }),
      onLoadingChange: () => ({ loading }) => ({ loading })
    }
  ),

  withHandlers({
    onCSVChange: ({ onVariantsChange, onHeadersChange }) => async (e) => {
      const files = Array.from(e.target.files);
      if (files.length) {
        csv.parse(files[0], {
          complete: result => {
            const variants = [];
            let headers;

            result.data.forEach((line, i) => {
              if (i === 0) {
                headers = line;

                onHeadersChange({ headers });
                return;
              }
              
              const row = {};

              headers.forEach((header, index) => {
                row[header] = line[index];
              });
              
              variants.push(row);
            });

            if (
              !headers.includes('parent') ||
              !headers.includes('stock') ||
              !headers.includes('sku') ||
              !headers.includes('price')
            ) {
              alert('Missing required fields');
              console.log('Required headers: stock, parent, sku, price');
              console.log(`Headers provided: ${JSON.stringify(headers)}`);
            }

            onVariantsChange({ variants });
          },

          error: err => {
            console.log(err);
            alert(err);
          },

          delimiter: ','
        });
      }
    },

    onSubmit: ({
      variants,
      headers,
      createVariant,
      onLoadingChange,
      onVariantsChange,
      onHeadersChange,
      mixpanel
    }) => async () => {
      onLoadingChange({ loading: true });

      try {
        for (let i = 0; i < variants.length; i += 1) {
          const variant = variants[i];

          // Format options to send in the mutation
          const optionHeaders = headers.filter(h => (
            h !== 'price' &&
            h !== 'sku' &&
            h !== 'parent' &&
            h !== 'stock' &&
            h !== 'description'
          ));
          const options = optionHeaders
            .map(h => variant[h] && ({ name: h, value: variant[h] }))
            .filter(o => o !== undefined && o !== '');

          // Format the variant to send in the mutation
          const formattedVariant = {
            parent: variant.parent,
            price: variant.price.replace('$', ''),
            sku: variant.sku !== '' ? variant.sku : i,
            name: variant.name && variant.name,
            description: variant.description && variant.description,
            stock: variant.stock !== '' ? variant.stock : undefined
          };
  
          const createdVariant = await createVariant({
            variables: {
              variant: formattedVariant,
              options
            }
          });
          
          mixpanel.track('Product Created', Object.assign({}, formattedVariant, {
            id: createdVariant.id,
            stock: formattedVariant.stock || null,
            createdInHarness: true
          }));
        }
        
        onVariantsChange({ variants: [] });
        onHeadersChange({ headers: [] });
        alert('Import successful');
      } catch (err) {
        console.log(err);
        alert(err);
      }

      onLoadingChange({ loading: false });
    }
  })
)(CreateVariants);
