import React, { PureComponent } from 'react';

import {
  Typography,
  Card,
  CardContent,
  TextField,
  Checkbox,
  CardActions,
  CardMedia
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

class Video extends PureComponent {
  render() {
    const { classes, video, name, setName, onNameChange } = this.props;

    return (
      <Card className="video" classes={{ root: video.visible ? classes.card : classes.hidden }}>
        <CardContent>
          <TextField
            variant="outlined"
            value={name}
            onChange={(e) => setName({ name: e.target.value })}
            onBlur={() => name !== video.name && onNameChange({ name })}
            style={{ width: '100%' }}
          />
          <Typography classes={{ root: classes.id }}>{`ID: ${video.id}`}</Typography>
        </CardContent>

        <a
          href={video.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <CardMedia
            image={video.thumbnailUrl}
            title={video.name}
            className="image"
          />
        </a>

        <CardActions disableActionSpacing className="actions">
          <div className="action">
            <Checkbox
              color="primary"
              checked={video.featured}
              onChange={(e, value) => {
                this.props.onFeaturedChange({ featured: value });
              }}
            />
            <Typography>Featured?</Typography>
          </div>

          <div className="action">
            <Checkbox
              color="primary"
              checked={!video.visible}
              onChange={(e, value) => {
                this.props.onDeletedChange({ visible: !value });
              }}
            />
            <Typography>Deleted?</Typography>
          </div>
        </CardActions>
      </Card>
    );
  }
}

const styles = {
  icon: {
    color: '#fff'
  },

  hidden: {
    opacity: 0.1,
    backgroundColor: '#5b5d6c'
  },

  card: {
    backgroundColor: '#5b5d6c'
  },

  id: {
    color: '#FFF',
    backgroundColor: '#333542',
    borderRadius: 3,
    paddingRight: 5,
    paddingLeft: 5,
    marginTop: 5,
    fontSize: 14,
    width: 'fit-content'
  }
};

export default withStyles(styles)(Video);
