import gql from 'graphql-tag';

export const GetAccounts = gql`
  query GetAccounts(
    $account: QueryableAccount!
  ) {
    admin {
      accounts(account: $account) {
        id
        name
        username
        email
        picture
      }
    }
  }
`;
