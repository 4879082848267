import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Typography, Checkbox, TextField } from '@material-ui/core';

export const CategoryListItem = SortableElement(
  ({ value, onUserSelectableChange, onActiveChange, onNameChange }) => {
    const [name, setName] = useState(value.name);

    return (
      <li className="draggable-category">
        <TextField
          variant="outlined"
          value={name}
          onChange={e => setName(e.target.value)}
          onBlur={() => name !== value.name && onNameChange(name)}
          style={{ width: '100%' }}
        />
        
        <div className="controls">
          <div className="flex">
            <Checkbox checked={value.userSelectable} color="primary" onChange={onUserSelectableChange} />
            <Typography>User Selectable</Typography>
            
            <Checkbox checked={value.active} color="primary" onChange={onActiveChange} />
            <Typography>Visible on Explore</Typography>
          </div>

          <Typography>ID</Typography>
          <input className="code-input" value={value.id} readOnly />

          {/* <IconButton onClick={onDelete} className="delete-button">
            <DeleteIcon />
          </IconButton> */}
        </div>
      </li>
    );
  }
);

export const CategoryList = SortableContainer(
  ({ items, onUserSelectableChange, onActiveChange, onNameChange, onDelete }) => (
    <ul className="draggable-list">
      {items.map((category, index) => (
        <CategoryListItem
          key={category.id}
          index={index}
          value={category} 
          onUserSelectableChange={(e) => onUserSelectableChange(category, e.target.checked)}
          onActiveChange={(e) => onActiveChange(category, e.target.checked)}
          onNameChange={(e) => onNameChange(category, e)}
        />
      ))}
    </ul>
  )
);
