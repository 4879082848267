import React, { Component } from 'react';

import { AppBarContainer as AppBar } from './AppBar';

import { withRouter } from 'react-router-dom';

class AppWrapper extends Component {
  componentDidMount() {
    if (
      (
        !this.props.account.isLoggedIn ||
        !this.props.account.roles ||
        !this.props.account.roles.includes('admin')
      ) && this.props.location.pathname !== '/login'
    ) {
      this.props.history.push('/login');
    }
  }

  render() {
    return (
      <div className="app-wrapper">
        <AppBar account={this.props.account} onLogout={this.props.onLogout} />
        <div className="page-content">
          {
            <React.Fragment>{this.props.children}</React.Fragment>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(AppWrapper);
