import React, { Component } from 'react';

import { CircularProgress } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import AppWrapper from './AppWrapper';

import { LoginContainer as Login } from '../pages/Login';
import { ProductsContainer as Products } from '../pages/Products';
import { UsersContainer as Users } from '../pages/Users';
import { VideosContainer as Videos } from '../pages/Videos';
import { CreateVideosContainer as CreateVideos } from '../pages/CreateVideos';
import { CreateProductsContainer as CreateProducts } from '../pages/CreateProducts';
import { CreateVariantsContainer as CreateVariants } from '../pages/CreateVariants';
import { CategoriesContainer as Categories } from '../pages/Categories';
import { CreateUsersContainer as CreateUsers } from '../pages/CreateUsers';
import Error404 from '../pages/Error404';

import './AppRouter.css';

class AppRouter extends Component {
  render() {
    const { isAccountLoaded } = this.props.account;

    if (!isAccountLoaded) {
      return (
        <div className="center">
          <CircularProgress />
        </div>
      );
    }

    return (
      <Router>
        <AppWrapper account={this.props.account} onLogout={this.props.onLogout}>
          <Switch>
            <Route
              exact
              path="/login" 
              render={(routeProps) => {
                if (this.props.account.isLoggedIn && this.props.account.roles.includes('admin')) {
                  return <Redirect to="/products" />;
                }

                return <Login {...routeProps} />;
              }}
            />
          
            <Route
              exact
              path="/"
              render={() => {
                return <Redirect to="/products" />;
              }}
            />

            <Route
              exact
              path="/products"
              render={(routeProps) => {
                return <Products {...routeProps} />;
              }}
            />

            <Route
              exact
              path="/videos"
              render={(routeProps) => {
                return <Videos {...routeProps} />;
              }}
            />

            <Route
              exact
              path="/users"
              render={(routeProps) => {
                return <Users {...routeProps} />;
              }}
            />

            <Route
              exact
              path="/create/videos"
              render={(routeProps) => {
                return <CreateVideos {...routeProps} />;
              }}
            />

            <Route
              exact
              path="/create/users"
              render={(routeProps) => {
                return <CreateUsers {...routeProps} />;
              }}
            />

            <Route
              exact
              path="/create/products"
              render={(routeProps) => {
                return <CreateProducts {...routeProps} />;
              }}
            />

            <Route
              exact
              path="/create/variants"
              render={(routeProps) => {
                return <CreateVariants {...routeProps} />;
              }}
            />

            <Route
              exact
              path="/categories"
              render={(routeProps) => {
                return <Categories {...routeProps} />;
              }}
            />

            <Route
              render={() => {
                return <Error404 />;
              }}
            />
          </Switch>
        </AppWrapper>
      </Router>
    );
  }
}

export default AppRouter;
