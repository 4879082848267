import React, { PureComponent } from 'react';

import { Typography } from '@material-ui/core';

class Error404 extends PureComponent {
  render() {
    return (
      <div className="404">
        <Typography variant="h3">
          404 - Page not found
        </Typography>
      </div>
    )
  }
}

export default Error404;
