import { compose, withStateHandlers, withHandlers, withProps } from 'recompose';

import csv from 'papaparse';
import { graphql } from 'react-apollo';

import CreateUsers from './CreateUsers';
import { CreateUsersMutation } from './mutations';
import UploadHelper from '../../helpers/upload';

export default compose(
  graphql(
    CreateUsersMutation,
    { name: 'createUsers' }
  ),

  withStateHandlers(
    {
      headers: [],
      users: [],
      loading: false,
      isEmailSent: true
    },
    {
      onHeadersChange: () => ({ headers }) => ({ headers }),
      onUsersChange: () => ({ users }) => ({ users }),
      onLoadingChange: () => ({ loading }) => ({ loading }),
      onUploadEnd: () => () => ({ isUploading: false, isToastVisible: true }),
      onHideToast: () => () => ({ isToastVisible: false }),
      onEmailSentChanged: ({ isEmailSent }) => () => ({ isEmailSent: !isEmailSent })
    }
  ),

  withHandlers({
    onCSVChange: ({ onUsersChange, onHeadersChange }) => async (e) => {
      const files = Array.from(e.target.files);
      if (files.length) {
        csv.parse(files[0], {
          complete: result => {
            const users = [];
            let headers;

            result.data.forEach((line, i) => {
              if (i === 0) {
                headers = line;

                if (!headers.includes('image')) {
                  headers.push('image');
                }

                onHeadersChange({ headers });
                return;
              }
              
              const row = {};

              headers.forEach((header, index) => {
                if (header === 'username') {
                  row[header] = line[index].replace(/[^\w\d_+\-.]+/g, '');
                } else {
                  row[header] = line[index];
                }
              });
              row.image = null;
              users.push(row);
            });

            if (
              !headers.includes('name') ||
              !headers.includes('email') ||
              !headers.includes('password') ||
              !headers.includes('username') ||
              !headers.includes('bio')
            ) {
              alert('Missing required fields');
              console.log('Required headers: name, email, password, username, bio');
              console.log(`Headers provided: ${JSON.stringify(headers)}`);
            }

            onUsersChange({ users });
          },

          error: err => {
            console.log(err);
            alert(err);
          },

          delimiter: ','
        });
      }
    },

    onImageChange: ({ users, onUsersChange }) => async ({ e, index }) => {
      const newUsers = users;
      const file = e.target.files[0];

      newUsers[index] = Object.assign(
        {},
        newUsers[index],
        { image: { loading: true, file } }
      );

      onUsersChange({ users: newUsers });

      try {
        const { url, signedUrl } = await UploadHelper.getSignedUrl(UploadHelper.AVATAR, {
          contentType: file.type
        });

        await UploadHelper.uploadToS3(signedUrl, file, () => {});

        newUsers[index] = Object.assign(
          {},
          newUsers[index],
          { image: { loading: false, url } }
        );
  
        onUsersChange({ users: newUsers });
      } catch (err) {
        console.log(err);
        alert(err);
        delete newUsers[index].image;
      }
    },

    onImageRemove: ({ users, onUsersChange }) => async ({ index }) => {
      const newUsers = users;
      delete newUsers[index].image;
      onUsersChange({ users: newUsers });
    },

    onSubmit: ({
      users,
      createUsers,
      onLoadingChange,
      onUsersChange,
      onHeadersChange,
      isEmailSent
    }) => async () => {
      onLoadingChange({ loading: true });

      try {
        const formattedUsers = [];
        for (let i = 0; i < users.length; i += 1) {
          const user = users[i];
          const formattedUser = {
            name: user.name,
            bio: user.bio,
            email: user.email,
            password: user.password,
            username: user.username, 
            picture: user.image.url
          };
          formattedUsers.push(formattedUser);
        }

        await createUsers({
          variables: {
            users: formattedUsers,
            isEmailSent
          }
        });
        
        onUsersChange({ users: [] });
        onHeadersChange({ headers: [] });
        alert('Import successful');
      } catch (err) {
        console.log(err);
        alert(err);
      }

      onLoadingChange({ loading: false });
    }
  }), 

  withProps(props => {
    for (let i = 0; i < props.users.length; i += 1) {
      if (
        !props.users[i].image ||
        props.users[i].image.loading
      ) {
        return {
          isSubmitEnabled: false
        };
      }
    }

    return {
      isSubmitEnabled: true
    };
  })

)(CreateUsers);
