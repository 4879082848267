import { compose, withStateHandlers, withHandlers } from 'recompose';

import { graphql } from 'react-apollo';
import {
  DeleteVideo,
  ResurrectVideo,
  FeatureVideo,
  UnfeatureVideo,
  ChangeVideoName
} from '../mutations';

import Video from './Video';

export default compose(
  graphql(DeleteVideo, { name: 'deleteVideo' }),
  graphql(ResurrectVideo, { name: 'resurrectVideo' }),
  graphql(FeatureVideo, { name: 'featureVideo' }),
  graphql(UnfeatureVideo, { name: 'unfeatureVideo' }),
  graphql(ChangeVideoName, { name: 'changeVideoName' }),

  withStateHandlers(
    ({ video = { name: '' } }) => ({
      name: video.name
    }),
    ({
      setName: () => ({ name }) => ({ name })
    })
  ),

  withHandlers({
    onFeaturedChange: ({
      video,
      refetch,
      featureVideo,
      unfeatureVideo
    }) => async ({ featured }) => {
      if (featured) {
        await featureVideo({
          variables: {
            video: video.id
          }
        });
      } else {
        await unfeatureVideo({
          variables: {
            video: video.id
          }
        });
      }

      refetch();
    },

    onDeletedChange: ({
      video,
      refetch,
      deleteVideo,
      resurrectVideo
    }) => async ({ visible }) => {
      if (visible) {
        await resurrectVideo({
          variables: {
            video: video.id
          }
        });
      } else {
        await deleteVideo({
          variables: {
            video: video.id
          }
        });
      }

      refetch();
    },

    onNameChange: ({
      video,
      refetch,
      changeVideoName
    }) => async ({ name }) => {
      await changeVideoName({
        variables: {
          video: video.id,
          name
        }
      });

      refetch();
    }
  })
)(Video);
