import { compose, withStateHandlers } from 'recompose';

import Product from './Product';

export default compose(
  withStateHandlers(
    {
      expanded: false
    },
    {
      onExpandClick: ({ expanded }) => () => ({ expanded: !expanded })
    }
  )
)(Product);
