import { compose, withProps, withStateHandlers } from 'recompose';

import { graphql } from 'react-apollo';
import { GetVideos } from './queries';

import Videos from './Videos';

export default compose(
  withStateHandlers(
    {
      search: '',
      featured: false
    },
    ({
      onSearchChange: () => (e) => ({ search: e.target.value }),
      onFeaturedChange: () => (e, value) => ({ featured: value })
    })
  ),

  graphql(GetVideos, {
    options: (props) => ({
      variables: {
        video: {
          name: {
            contains: props.search
          },
          featured: props.featured ? props.featured : undefined,
          createdAt: {
            orderBy: 'Descending'
          },
          limit: props.featured ? undefined : 10
        }
      }
    })
  }),

  withProps((props) => ({
    loading: props.data.loading,
    error: props.data.error,
    videos: (
      props.data.loading ||
      props.data.error
    ) ? [] : props.data.admin.videos,
    refetch: (
      props.data.loading ||
      props.data.error
    ) ? () => {} : props.data.refetch
  }))
)(Videos);
