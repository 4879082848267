import gql from 'graphql-tag';

export const GetProducts = gql`
  query GetProducts(
    $product: QueryableProduct
  ) {
    admin {
      products (
        product: $product
      ) {
        id
        name
        price
        description
        private

        images {
          id
          url
        }

        owner {
          id
          name
          username
        }
      }
    }
  }
`;
