import gql from 'graphql-tag';

export const GetVideos = gql`
  query GetVideos(
    $video: QueryableVideo
  ) {
    admin {
      videos (
        video: $video
      ) {
        id
        name
        url
        thumbnailUrl
        visible
        featured

        owner {
          id
          name
          picture
        }
      }
    }
  }
`;
