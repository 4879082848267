import { compose, withStateHandlers, withHandlers, withProps, getContext } from 'recompose';
import PropTypes from 'prop-types';

import csv from 'papaparse';
import UploadHelper from '../../helpers/upload';

import { graphql } from 'react-apollo';
import { CreateProduct } from './mutations';

import moment from 'moment';

import CreateProducts from './CreateProducts';

export default compose(
  getContext(
    { mixpanel: PropTypes.object }
  ),

  graphql(
    CreateProduct,
    { name: 'createProduct' }
  ),

  withStateHandlers(
    {
      headers: [],
      products: [],
      loading: false
    },
    {
      onHeadersChange: () => ({ headers }) => ({ headers }),
      onProductsChange: () => ({ products }) => ({ products }),
      onLoadingChange: () => ({ loading }) => ({ loading })
    }
  ),

  withHandlers({
    onCSVChange: ({ onProductsChange, onHeadersChange }) => async (e) => {
      const files = Array.from(e.target.files);
      if (files.length) {
        csv.parse(files[0], {
          complete: result => {
            const products = [];
            let headers;

            result.data.forEach((line, i) => {
              if (i === 0) {
                headers = line;

                if (!headers.includes('image')) {
                  headers.push('image');
                }
                if (!headers.includes('videos')) {
                  headers.push('videos');
                }

                onHeadersChange({ headers });
                return;
              }
              
              const row = {};

              headers.forEach((header, index) => {
                if (header === 'image' || header === 'videos') {
                  row[header] = null;
                } else {
                  row[header] = line[index];
                }
              });
              
              products.push(row);
            });

            if (
              !headers.includes('name') ||
              !headers.includes('description') ||
              !headers.includes('stock') ||
              !headers.includes('owner') ||
              !headers.includes('image')
            ) {
              alert('Missing required fields');
              console.log('Required headers: name, description, stock, owner, image');
              console.log(`Headers provided: ${JSON.stringify(headers)}`);
            }

            onProductsChange({ products });

            if (
              (
                headers.includes('opportunity_expiresAt') &&
                headers.includes('opportunity_amount') &&
                headers.includes('opportunity_visibility')
              ) || (
                !headers.includes('opportunity_expiresAt') &&
                !headers.includes('opportunity_amount') &&
                !headers.includes('opportunity_visibility')
              )
            ) {
              return;
            }

            alert('Some opportunity fields were provided, but not all. Opportunities will not be created for these products.');
          },

          error: err => {
            console.log(err);
            alert(err);
          },

          delimiter: ','
        });
      }
    },

    onImageChange: ({ products, onProductsChange }) => async ({ e, index }) => {
      const newProducts = products;
      const file = e.target.files[0];

      newProducts[index] = Object.assign(
        {},
        newProducts[index],
        { image: { loading: true, file } }
      );

      onProductsChange({ products: newProducts });

      try {
        const { url, signedUrl } = await UploadHelper.getSignedUrl(UploadHelper.PRODUCT_IMAGE, {
          contentType: file.type
        });

        await UploadHelper.uploadToS3(signedUrl, file, () => {});

        newProducts[index] = Object.assign(
          {},
          newProducts[index],
          { image: { loading: false, url } }
        );
  
        onProductsChange({ products: newProducts });
      } catch (err) {
        console.log(err);
        alert(err);
        delete newProducts[index].image;
      }
    },

    onImageRemove: ({ products, onProductsChange }) => async ({ index }) => {
      const newProducts = products;
      delete newProducts[index].image;
      onProductsChange({ products: newProducts });
    },

    onVideosChange: ({ products, onProductsChange }) => async ({ e, index }) => {
      const newProducts = products;
      const files = Array.from(e.target.files);

      newProducts[index] = Object.assign(
        {},
        newProducts[index],
        { videos: { loading: true, files } }
      );

      onProductsChange({ products: newProducts });

      const uploadPromises = files.map(async file => {
        const { url, signedUrl } = await UploadHelper.getSignedUrl(UploadHelper.VIDEO, {
          contentType: file.type
        });

        await UploadHelper.uploadToS3(signedUrl, file, () => {});

        const splitUrl = url.split('/');
        const wowzaId = splitUrl[splitUrl.length - 1];

        const name = file.name.split('.').slice(0, -1).join('.');

        return {
          wowzaId,
          url,
          name
        };
      });

      try {
        const videos = await Promise.all(uploadPromises);

        newProducts[index] = Object.assign(
          {},
          newProducts[index],
          { videos }
        );

        onProductsChange({ products: newProducts });
      } catch (err) {
        console.log(err);
        alert(err.message);
      }
    },

    onVideosRemove: ({ products, onProductsChange }) => async ({ index }) => {
      const newProducts = products;
      delete newProducts[index].videos;
      onProductsChange({ products: newProducts });
    },

    onSubmit: ({
      products,
      createProduct,
      onLoadingChange,
      onProductsChange,
      onHeadersChange,
      mixpanel
    }) => async () => {
      onLoadingChange({ loading: true });

      try {
        for (let i = 0; i < products.length; i += 1) {
          const product = products[i];
          const formattedProduct = {
            price: product.price ? product.price.replace('$', '') : undefined,
            sku: product.sku ? product.sku : undefined,
            name: product.name,
            description: product.description,
            owner: product.owner,
            stock: product.stock ? product.stock : undefined,
            categories: product.categories ? product.categories.split(',') : undefined
          };
  
          const image = {
            url: product.image.url
          };
  
          let videos;
          if (product.videos) {
            videos = product.videos.map(video => {
              return {
                wowzaId: video.wowzaId,
                name: video.name
              };
            });
          }
  
          let opportunity;
          if (
            product.opportunity_visibility &&
            product.opportunity_amount &&
            product.opportunity_expiresAt &&
            product.opportunity_visibility !== '' &&
            product.opportunity_amount !== '' &&
            product.opportunity_expiresAt !== ''
          ) {
            opportunity = {
              visibility: `${product.opportunity_visibility[0].toUpperCase()}${product.opportunity_visibility.slice(1)}`,
              amount: product.opportunity_amount.replace('$', '').replace('%', ''),
              isPercentage: product.opportunity_amount.includes('%'),
              expiresAt: moment(product.opportunity_expiresAt).toISOString()
            };
          }
  
          const createdProduct = await createProduct({
            variables: {
              product: formattedProduct,
              image,
              opportunity,
              videos
            }
          });

          if (
            product.opportunity_visibility &&
            product.opportunity_amount &&
            product.opportunity_expiresAt &&
            product.opportunity_visibility !== '' &&
            product.opportunity_amount !== '' &&
            product.opportunity_expiresAt !== ''
          ) {
            const formattedOpportunity = {
              OpportunityVisibility: opportunity.visibility,
              OpportunityAmount: opportunity.amount,
              IsPercentage: opportunity.isPercentage,
              OpportunityExpiresAt: opportunity.expiresAt,
              ProductId: createdProduct.id
            };

            mixpanel.track('Opportunity Created', Object.assign({}, formattedOpportunity, {
              createdInHarness: true
            }));
          }
          
          mixpanel.track('Product Created', Object.assign({}, formattedProduct, {
            id: createdProduct.id,
            stock: formattedProduct.stock || null,
            createdInHarness: true
          }));
        }
        
        onProductsChange({ products: [] });
        onHeadersChange({ headers: [] });
        alert('Import successful');
      } catch (err) {
        console.log(err);
        alert(err);
      }

      onLoadingChange({ loading: false });
    }
  }), 

  withProps(props => {
    for (let i = 0; i < props.products.length; i += 1) {
      if (
        !props.products[i].image ||
        props.products[i].image.loading ||
        (
          props.products[i].videos &&
          !props.products[i].videos.length &&
          props.products[i].videos.loading
        )
      ) {
        return {
          isSubmitEnabled: false
        };
      }
    }

    return {
      isSubmitEnabled: true
    };
  })
)(CreateProducts);
