import React, { Component } from 'react';

import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { Table } from '../../components/Table';

class CreateUsers extends Component {
  render() {
    return (
      <div className="create-products">
        {
          (!this.props.users || !this.props.users.length)
            ? (
              <Button
                variant="contained"
                component="label"
              >
                Upload CSV
                <input
                  type="file"
                  accept="text/csv"
                  style={{ display: 'none' }}
                  onChange={this.props.onCSVChange}
                />
              </Button>
            )
            : (
              <div>
                <Table
                  headers={this.props.headers}
                  rows={this.props.users}
                  onImageChange={this.props.onImageChange}
                  onImageRemove={this.props.onImageRemove}
                  onSubmit={this.props.onSubmit}
                  isSubmitEnabled={this.props.isSubmitEnabled}
                  loading={this.props.loading}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={this.props.isEmailSent}
                      onChange={this.props.onEmailSentChanged}
                      value="isEmailSentValue"
                      color="primary"
                    />
                  )}
                  label="Send Email When Done"
                />
                
              </div>

            )
        }
      </div>
    );
  }
}

export default CreateUsers;
