import React, { Component } from 'react';

import {
  Table as MaterialTable,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableFooter,
  Paper,
  Button,
  CircularProgress,
  withStyles
} from '@material-ui/core';
import UploadableContentCell from './UploadableContentCell';

import './Table.css';

class Table extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Paper className="table">
        <MaterialTable>
          <TableHead>
            <TableRow>
              {
                this.props.headers.map(header => {
                  return (
                    <TableCell key={header} variant="head" classes={{ root: classes.header }}>
                      {header}
                    </TableCell>
                  );
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.props.rows.map((row, index) => {
                return (
                  <TableRow key={index} classes={{ root: index % 2 === 0 ? classes.evenRow : null }}>
                    {
                      this.props.headers.map(header => {
                        switch (header) {
                          case 'image':
                            return (
                              <UploadableContentCell
                                key={header + index}
                                onUploadableContentChange={this.props.onImageChange}
                                onUploadableContentRemove={this.props.onImageRemove}
                                content={row.image}
                                accept="image/*"
                                index={index}
                              />
                            );

                          case 'video':
                            return (
                              <UploadableContentCell
                                key={header + index}
                                onUploadableContentChange={this.props.onVideoChange}
                                onUploadableContentRemove={this.props.onVideoRemove}
                                content={row.video}
                                accept="video/*"
                                index={index}
                              />
                            );

                          case 'videos':
                            return (
                              <UploadableContentCell
                                multiple
                                key={header + index}
                                onUploadableContentChange={this.props.onVideosChange}
                                onUploadableContentRemove={this.props.onVideosRemove}
                                accept="video/*"
                                content={row.videos}
                                index={index}
                              />
                            );

                          default: 
                            return <TableCell key={header + index}>{row[header]}</TableCell>;
                        }
                      })
                    }
                  </TableRow>
                );
              })
            }
          </TableBody>
          <TableFooter>
            <TableRow>
              {
                this.props.headers.map((header, index) => {
                  if (index === 0) return null;
                  return <TableCell key={index} />;
                })
              }
              <TableCell>
                {
                  this.props.loading
                    ? <CircularProgress />
                    : (
                      <Button
                        variant="contained"
                        disabled={!this.props.isSubmitEnabled}
                        onClick={this.props.onSubmit}
                      >
                        Submit
                      </Button>
                    )
                }
              </TableCell>
            </TableRow>
          </TableFooter>
        </MaterialTable>
      </Paper>
    );
  }
}

const styles = {
  header: {
    fontWeight: 800,
    fontSize: 14,
    textTransform: 'uppercase',
  },

  evenRow: {
    backgroundColor: '#4F5160'
  }
};

export default withStyles(styles)(Table);
