import gql from 'graphql-tag';

export const GetCategories = gql`
  query GetCategories {
    admin {
      categories {
        id
        name
        userSelectable
        active
        order
      }
    }
  }
`;

export const CreateCategory = gql`
  mutation CreateCategory($category: AdminCategoryInput!) {
    adminCreateCategory(category: $category) {
      id
    }
  }
`;

export const UpdateCategory = gql`
  mutation UpdateCategory($category: AdminCategoryInput!) {
    adminUpdateCategory(category: $category) {
      id
    }
  }
`;
