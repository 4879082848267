import gql from 'graphql-tag';

export const CreateVariant = gql`
  mutation CreateVariant (
    $variant: AdminVariantInput!
    $options: [AdminProductOptionInput]!
  ) {
    adminCreateVariant(
      variant: $variant
      options: $options
    ) {
      id
    }
  }
`;
