import _ from 'lodash';
import { compose, withStateHandlers, withHandlers } from 'recompose';
import withLifecycle from '@hocs/with-lifecycle';
import { arrayMove } from 'react-sortable-hoc';
import { graphql } from 'react-apollo';
import { GetCategories, CreateCategory, UpdateCategory } from './queries';
import Categories from './Categories';

export default compose(
  graphql(
    GetCategories
  ),

  graphql(
    UpdateCategory,
    { 
      name: 'updateCategory',
      options: () => ({
        refetchQueries: [{ query: GetCategories }],
      })
    }
  ),

  graphql(
    CreateCategory,
    { 
      name: 'createCategory',
      options: () => ({
        refetchQueries: [{ query: GetCategories }],
      })
    }
  ),

  withStateHandlers(
    ({ data }) => ({
      categories: data.loading ? [] : data.admin.categories
    }),
    {
      onCategoriesUpdate: () => (categories) => ({ categories })
    }
  ),

  withLifecycle({
    onDidUpdate(props, nextProps) {
      if (
        (props.data.loading === true && nextProps.data.loading === false) ||
        !_.isEqual(props.data.admin.categories, nextProps.data.admin.categories)
      ) {
        props.onCategoriesUpdate(
          nextProps.data.admin.categories.sort((a, b) => a.order - b.order)
        );
      }
    }
  }),

  withHandlers({
    onSortEnd: ({
      categories,
      onCategoriesUpdate,
      updateCategory 
    }) => async ({ oldIndex, newIndex }) => {
      /**
       * TODO: This could be made more optimal, probably. As it stands,
       * it updates each category in the system with a new order, even though
       * theoretically only two category orders will have changed.
       */

      const sortedCategories = arrayMove(categories, oldIndex, newIndex);
      onCategoriesUpdate(sortedCategories);

      const updatePromises = sortedCategories.map((c, index) => updateCategory({
        variables: {
          category: {
            id: c.id,
            order: index
          }
        }
      }));

      await Promise.all(updatePromises);
    },

    onUserSelectableChange: ({
      updateCategory
    }) => async (category, isUserSelectable) => {
      await updateCategory({
        variables: {
          category: { id: category.id, userSelectable: isUserSelectable }
        }
      });
    },

    onActiveChange: ({
      updateCategory
    }) => async (category, isActive) => {
      await updateCategory({
        variables: {
          category: { id: category.id, active: isActive }
        }
      });
    },

    onNameChange: ({
      updateCategory
    }) => async (category, name) => {
      await updateCategory({
        variables: {
          category: { id: category.id, name }
        }
      });
    },

    onCreate: ({
      categories,
      createCategory
    }) => async () => {
      const categoryName = window.prompt('What is the category name?');
      if (!categoryName) return;

      await createCategory({
        variables: {
          category: {
            name: categoryName,
            active: false,
            userSelectable: false,
            order: categories.length + 1
          }
        }
      });
    }
  })
)(Categories);
