import React, { PureComponent } from 'react';

import { TextField, Button, Snackbar, Typography } from '@material-ui/core';

import './Login.css';

class Login extends PureComponent {
  render() {
    return (
      <div className="login">
        <Snackbar
          message={<Typography color="secondary">{`Error: ${this.props.error}`}</Typography>}
          open={this.props.isErrorVisible}
          autoHideDuration={3000}
          onClose={this.props.onErrorHide}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top'
          }}
        />
        <form className="form" autoComplete="off">
          <TextField
            required
            autoFocus
            onChange={this.props.onUsernameChange}
            label="Username"
            placeholder=""
            margin="normal"
          />

          <TextField
            required
            onChange={this.props.onPasswordChange}
            type="password"
            label="Password"
            placeholder=""
            margin="normal"
          />

          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              this.props.onSubmit({ username: this.props.username, password: this.props.password });
            }}
            type="submit"
            disabled={this.props.username === '' || this.props.password === ''}
          >
            Login
          </Button>
        </form>
      </div>
    )
  }
}

export default Login;
