import { compose, withStateHandlers } from 'recompose';

import AppBar from './AppBar';

export default compose(
  withStateHandlers(
    {
      anchorEl: null,
      isDrawerVisible: false
    },
    {
      onMenuOpen: () => event => ({ anchorEl: event.target }),
    
      onMenuClose: () => () => ({ anchorEl: null }),
    
      onDrawerOpen: () => () => ({ isDrawerVisible: true }),
    
      onDrawerClose: () => () => ({ isDrawerVisible: false })
    }
  )
)(AppBar);
