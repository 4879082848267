const Auth0 = require('auth0-js');
const jwt = require('jwt-decode');

const auth0 = new Auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID
});

class AccountHelper {
  static saveSession(idToken, accessToken) {
    window.localStorage.setItem('zipline_access_token', accessToken);
    window.localStorage.setItem('zipline_id_token', idToken);
  }

  static getUserFromSession() {
    const storedToken = window.localStorage.getItem('zipline_id_token');
    if (!storedToken) return null;

    const decodedToken = jwt(storedToken);
    const metadata = decodedToken['https://zipline.co/user_metadata'];
    const { roles, features } = decodedToken['https://zipline.co/app_metadata'] || {};
    return {
      id: decodedToken.sub,
      name: metadata.name,
      username: decodedToken.nickname,
      picture: metadata.picture,
      email: decodedToken.email,
      isLoggedIn: metadata.isAnonymous !== 'true',
      roles,
      features
    };
  }

  static async loginWithCredentials(username, password) {
    return new Promise((resolve, reject) => {
      auth0.client.login({
        realm: 'Username-Password-Authentication',
        username,
        password,
        scope: 'openid profile email user_metadata'
      }, (err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  }

  static async createUserAccount(username, email, password, metadata) {
    return new Promise((resolve, reject) => {
      auth0.signup({
        connection: 'Username-Password-Authentication',
        username,
        email,
        password,
        user_metadata: metadata
      }, (err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  }

  static sendPasswordReset(email) {
    return new Promise((resolve, reject) => {
      auth0.changePassword({
        connection: 'Username-Password-Authentication',
        email
      }, (err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  }
}

export default AccountHelper;
