import React, { Component } from 'react';

import {
  AppBar as TopBar,
  Toolbar,
  Button,
  CircularProgress,
  Avatar,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton
} from '@material-ui/core';
import {
  Menu as MenuIcon
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import Logo from './Logo_Type.svg';

import { withRouter } from 'react-router-dom';

class AppBar extends Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <TopBar position="sticky" color="secondary">
          <Toolbar>
            <IconButton onClick={this.props.onDrawerOpen} classes={{ root: classes.iconButton }}>
              <MenuIcon classes={{ root: classes.icon }} />
            </IconButton>
            <img src={Logo} style={{ height: 30, flex: 1 }} alt="logo" />
            {
              !this.props.account.isAccountLoaded &&
              <CircularProgress />
            }
            {
              this.props.account.isAccountLoaded && !this.props.account.isLoggedIn && (
                <Button
                  color="inherit"
                  onClick={() => {
                    this.props.history.push('/login');
                  }}
                >
                  Login
                </Button>
              )
            }
            {
              this.props.account.isAccountLoaded && this.props.account.isLoggedIn && (
                <React.Fragment>
                  <Button
                    aria-owns={this.props.anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.props.onMenuOpen}
                  >
                    <Avatar src={this.props.account.picture} />
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={this.props.anchorEl}
                    open={Boolean(this.props.anchorEl)}
                    onClose={this.props.onMenuClose}
                  >
                    <MenuItem onClick={this.props.onLogout}>Logout</MenuItem>
                  </Menu>
                </React.Fragment>
              )
            }
          </Toolbar>
        </TopBar>
        <Drawer
          open={this.props.isDrawerVisible}
          onClose={this.props.onDrawerClose}
        >
          <List component="nav">
            <ListItem
              button
              onClick={() => {
                this.props.history.push('/products');
              }}
            >
              <ListItemText primary="Manage Products" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.props.history.push('/videos');
              }}
            >
              <ListItemText primary="Manage Videos" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.props.history.push('/users');
              }}
            >
              <ListItemText primary="Manage Users" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.props.history.push('/categories');
              }}
            >
              <ListItemText primary="Manage Categories" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.props.history.push('/create/products');
              }}
            >
              <ListItemText primary="Create Products" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.props.history.push('/create/variants');
              }}
            >
              <ListItemText primary="Create Product Variants" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.props.history.push('/create/videos');
              }}
            >
              <ListItemText primary="Create Videos" />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                this.props.history.push('/create/users');
              }}
            >
              <ListItemText primary="Create Users" />
            </ListItem>
          </List>
        </Drawer>
      </React.Fragment>
    )
  }
}

const styles = {
  icon: {
    color: '#fff'
  },

  iconButton: {
    marginLeft: -20
  }
}

export default withStyles(styles)(withRouter(AppBar));
