import React, { Component } from 'react';

import { Button } from '@material-ui/core';
import { Table } from '../../components/Table';

import './CreateVideos.css';

class CreateVideos extends Component {
  render() {
    return (
      <div className="create-videos">
        {
          (!this.props.videos || !this.props.videos.length)
            ? (
              <Button
                variant="contained"
                component="label"
              >
                Upload CSV
                <input
                  type="file"
                  accept="text/csv"
                  style={{ display: "none" }}
                  onChange={this.props.onCSVChange}
                />
              </Button>
            )
            : (
              <Table
                headers={this.props.headers}
                rows={this.props.videos}
                onVideoChange={this.props.onVideoChange}
                onVideoRemove={this.props.onVideoRemove}
                onSubmit={this.props.onSubmit}
                isSubmitEnabled={this.props.isSubmitEnabled}
                loading={this.props.loading}
              />
            )
        }
      </div>
    );
  }
}

export default CreateVideos;
