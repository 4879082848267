import gql from 'graphql-tag';

export const CreateVideo = gql`
  mutation CreateVideo(
    $video: AdminVideoInput!
  ) {
    adminCreateVideo(video: $video) {
      id
    }
  }
`;
