import React, { Component } from 'react';

import {
  TableCell,
  Button,
  CircularProgress,
  Typography,
  IconButton
} from '@material-ui/core';
import {
  Delete as DeleteIcon
} from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

class UploadableContentCell extends Component {
  render() {
    const { classes } = this.props;

    return (
      <TableCell>
        {
          !this.props.content && 
            (
              <Button
                variant="contained"
                component="label"
              >
                Upload
                <input
                  multiple={this.props.multiple}
                  type="file"
                  accept={this.props.accept}
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    this.props.onUploadableContentChange({ e, index: this.props.index });
                  }}
                />
              </Button>
            )
        }
        {
          this.props.content && this.props.content.loading &&
          <CircularProgress />
        }
        {
          this.props.content && !this.props.content.loading && (
            <div className="uploaded">
              {
                this.props.content.url
                  ? (
                    <a
                      href={this.props.content.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      <Typography>Uploaded</Typography>
                    </a>
                  )
                  : (
                    <React.Fragment>
                      {
                        this.props.content.map((c, index) => (
                          <a
                            key={c.url}
                            href={c.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link"
                          >
                            <Typography>{+index + 1}</Typography>
                          </a>
                        ))
                      }
                    </React.Fragment>
                  )
              }
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  this.props.onUploadableContentRemove({ index: this.props.index });
                }}
                size="small"
              >
                <DeleteIcon classes={{ root: classes.icon }} />
              </IconButton>
            </div>
          )
        }
      </TableCell>
    );
  }
}

const styles = {
  cell: {
    flexDirection: 'row'
  },

  icon: {
    color: '#fff'
  }
};

export default withStyles(styles)(UploadableContentCell);
