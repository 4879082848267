import gql from 'graphql-tag';

export const CreateProduct = gql`
  mutation CreateProduct (
    $product: AdminProductInput!
    $image: AdminProductImageInput!
    $opportunity: AdminProductOpportunityInput
    $videos: [AdminProductVideoInput]
  ) {
    adminCreateProduct(
      product: $product,
      image: $image,
      opportunity: $opportunity,
      videos: $videos
    ) {
      id
    }
  }
`;
