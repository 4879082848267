const INITIAL_STATE = {
  id: '',
  name: '',
  username: '',
  picture: '',
  email: '',
  roles: [],
  features: [],
  isLoggedIn: false,
  isAccountLoaded: false,
  isLoggingIn: false
};

const account = (
  state = INITIAL_STATE,
  action
) => {
  if (action.namespace !== 'account') {
    return state;
  }

  switch (action.type) {
    case 'SET_ID':
      return { ...state, id: action.id };

    case 'SET_NAME':
      return { ...state, name: action.name };

    case 'SET_USERNAME':
      return { ...state, username: action.username };

    case 'SET_PICTURE':
      return { ...state, picture: action.picture };

    case 'SET_EMAIL':
      return { ...state, email: action.email };

    case 'SET_LOGGED_IN':
      return { ...state, isLoggedIn: action.isLoggedIn };

    case 'SET_ACCOUNT_LOADED':
      return { ...state, isAccountLoaded: action.isAccountLoaded };

    case 'SET_LOGGING_IN':
      return { ...state, isLoggingIn: action.isLoggingIn };

    case 'SET_ROLES':
      return { ...state, roles: action.roles };

    case 'SET_FEATURES':
      return { ...state, features: action.features };

    default:
      return state;
  }
};

export default account;
