import React, { Component } from 'react';

import {
  Typography,
  Button,
  Card,
  CardHeader,
  Collapse,
  CardContent,
  CardActions,
  CardMedia,
  IconButton
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

class Product extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Card className="product" classes={{ root: classes.card }}>
        <CardHeader
          title={`${this.props.product.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} - ${this.props.product.name}`}
          subheader={`ID: ${this.props.product.id}`}
          subheaderTypographyProps={{
            classes: { root: classes.id }
          }}
        />

        <CardMedia
          image={this.props.product.images[0].url}
          title={this.props.product.name}
          className="image"
        />

        <CardActions disableActionSpacing className="actions">
          {
            this.props.product.private
              ? (
                <Button
                  onClick={() => {
                    this.props.onUnhidePress(this.props.product.id);
                  }}
                >
                  Unhide Product?
                </Button>
              )
              : (
                <Button
                  onClick={() => {
                    this.props.onHidePress(this.props.product.id);
                  }}
                >
                  Hide Product?
                </Button>
              )
          }

          <IconButton
            onClick={this.props.onExpandClick}
            aria-expanded={this.props.expanded}
            aria-label="Show more"
          >
            {
              this.props.expanded
                ? <ExpandLessIcon classes={{ root: classes.icon }} />
                : <ExpandMoreIcon classes={{ root: classes.icon }} />
            }
          </IconButton>
        </CardActions>

        <Collapse in={this.props.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography>{this.props.product.description}</Typography>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

const styles = {
  icon: {
    color: '#fff'
  },

  card: {
    backgroundColor: '#5b5d6c'
  },

  id: {
    color: '#FFF',
    backgroundColor: '#333542',
    borderRadius: 3,
    paddingRight: 5,
    paddingLeft: 5,
    marginTop: 5,
    fontSize: 14,
    width: 'fit-content'
  }
};

export default withStyles(styles)(Product);
