import { compose, withHandlers, withProps, withStateHandlers } from 'recompose';

import { graphql } from 'react-apollo';
import { GetProducts } from './queries';
import { HideProduct, UnhideProduct } from './mutations';

import Products from './Products';

export default compose(
  withStateHandlers(
    {
      search: ''
    },
    ({
      onSearchChange: () => (e) => ({ search: e.target.value })
    })
  ),

  graphql(GetProducts, {
    options: (props) => {
      return {
        variables: {
          product: {
            name: {
              contains: props.search
            },
            createdAt: {
              orderBy: 'Descending'
            }
          }
        }
      }
    }
  }),
  graphql(HideProduct, {
    options: {
      refetchQueries: [{ query: GetProducts }]
    },
    name: 'hideProduct'
  }),
  graphql(UnhideProduct, {
    options: {
      refetchQueries: [{ query: GetProducts }]
    },
    name: 'unhideProduct'
  }),

  withHandlers({
    onHidePress: ({ hideProduct }) => async (product) => {
      await hideProduct({ variables: { productId: product }});
    },

    onUnhidePress: ({ unhideProduct }) => async (product) => {
      await unhideProduct({ variables: { productId: product }});
    }
  }),

  withProps((props) => {
    return {
      loading: props.data.loading,
      error: props.data.error,
      products: (
        props.data.loading ||
        props.data.error
      ) ? [] : props.data.admin.products
    }
  })
)(Products);
