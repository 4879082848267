import React, { Component } from 'react';

import {
  Paper,
  CircularProgress,
  Typography,
  InputBase
} from '@material-ui/core';
import {
  Search as SearchIcon
} from '@material-ui/icons';
import { ProductContainer as Product } from './Product';

import { withStyles } from '@material-ui/core/styles';
import './Products.css';

class Products extends Component {
  render() {
    const { loading, error, products, classes, search } = this.props;
    if (loading && search === '') {
      return <CircularProgress />;
    }

    if (error) {
      return (
        <div className="error">
          <Typography>{JSON.stringify(error, null, 2)}</Typography>
        </div>
      );
    }

    return (
      <div className="products">
        <Paper classes={{ root: classes.paper }}>
          <InputBase
            classes={{ root: classes.input }}
            placeholder="Search..."
            onChange={this.props.onSearchChange}
          />
          <SearchIcon classes={{ root: classes.icon }} />
        </Paper>
        {
          !this.props.search.length &&
          <Typography variant="h5" className="header">Recent Products</Typography>
        }
        {
          products.slice(0, 10).map(product => (
            <Product
              key={product.id}
              product={product}
              onHidePress={this.props.onHidePress}
              onUnhidePress={this.props.onUnhidePress}
            />
          ))
        }
      </div>
    );
  }
}

const styles = {
  paper: {
    padding: '10px 15px',
    marginBottom: 20,
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    maxWidth: 370
  },

  input: {
    display: 'flex',
    flex: 1
  },

  icon: {
    color: '#fff'
  }
};

export default withStyles(styles)(Products);
