import React, { Component } from 'react';

// Apollo
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, concat } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';

// Redux
import { compose, createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';
import { responsiveStoreEnhancer, responsiveStateReducer } from 'redux-responsive';
import { loginWithSavedSession } from '../actions/account';

import AppRouter from './AppRouterContainer';

class App extends Component {
  render() {
    const httpLink = new HttpLink({
      uri: process.env.REACT_APP_API_URL
    });

    const authMiddleware = new ApolloLink((operation, forward) => {
      const token = window.localStorage.getItem('zipline_id_token');

      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : null
        } 
      });
    
      return forward(operation);
    });

    const authErrorAfterware = onError(({
      networkError,
      graphQLErrors
    }) => {
      if (networkError) {
        console.error(networkError);
      }

      if (graphQLErrors) {
        graphQLErrors.forEach(e => console.error('GraphQL Error:', e.message));
      }

      if (networkError && networkError.statusCode === 401) {
        window.localStorage.removeItem('zipline_id_token');
        window.localStorage.removeItem('zipline_refresh_token');
        window.location.reload();
      }
    });

    const client = new ApolloClient({
      link: concat(authMiddleware, authErrorAfterware.concat(httpLink)),
      cache: new InMemoryCache()
    });

    const middleware = (
      (typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined')
        ? (
          compose(
            responsiveStoreEnhancer,
            applyMiddleware(thunk),
            window.__REDUX_DEVTOOLS_EXTENSION__() 
          )
        ) : (
          compose(
            responsiveStoreEnhancer,
            applyMiddleware(thunk)
          )
        )
    );
    /* eslint-enable no-underscore-dangle */

    const store = createStore(
      combineReducers({
        browser: responsiveStateReducer,
        ...reducers,
      }),
      middleware
    );

    store.dispatch(loginWithSavedSession());

    return (
      <ApolloProvider client={client}>
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </ApolloProvider>
    );
  }
}

export default App;
