import React, { PureComponent } from 'react';

import {
  Card,
  CardHeader,
  CardContent,
  CardMedia
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

class User extends PureComponent {
  render() {
    const { classes, user } = this.props;

    return (
      <Card className="user" classes={{ root: classes.card }}>
        <CardHeader
          title={`@${user.username} - ${user.name}`}
          subheader={`ID: ${user.id}`}
          subheaderTypographyProps={{
            classes: { root: classes.id }
          }}
        />

        <CardMedia
          image={user.picture}
          title={user.name}
          className="image"
        />

        <CardContent>
          <a href={`mailto:${user.email}`} className="email">{`${user.email}`}</a>
        </CardContent>
      </Card>
    );
  }
}

const styles = {
  card: {
    backgroundColor: '#5b5d6c'
  },

  id: {
    color: '#FFF',
    fontFamily: 'monospace',
    backgroundColor: '#333542',
    borderRadius: 3,
    paddingRight: 5,
    paddingLeft: 5,
    marginTop: 5,
    fontSize: 14,
    width: 'fit-content'
  }
};

export default withStyles(styles)(User);
