import React, { Component } from 'react';

import { Button } from '@material-ui/core';
import { Table } from '../../components/Table';

import './CreateProducts.css';

class CreateProducts extends Component {
  render() {
    return (
      <div className="create-products">
        {
          !this.props.products.length
            ? (
              <Button
                variant="contained"
                component="label"
              >
                Upload CSV
                <input
                  type="file"
                  accept="text/csv"
                  style={{ display: 'none' }}
                  onChange={this.props.onCSVChange}
                />
              </Button>
            )
            : (
              <Table
                headers={this.props.headers}
                rows={this.props.products}
                onImageChange={this.props.onImageChange}
                onImageRemove={this.props.onImageRemove}
                onVideosChange={this.props.onVideosChange}
                onVideosRemove={this.props.onVideosRemove}
                isSubmitEnabled={this.props.isSubmitEnabled}
                onSubmit={this.props.onSubmit}
                loading={this.props.loading}
              />
            )
        }
      </div>
    );
  }
}

export default CreateProducts;
