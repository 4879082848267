class UploadHelper {
  /** Upload Types */
  static PRODUCT_IMAGE = 'product_image';
  static VIDEO = 'video';
  static AVATAR = 'avatar';

  static getUploadEndpoint(resource) {
    switch (resource) {
      case 'product_image':
        return 'upload';
      case 'video':
        return 'upload/video';
      case 'avatar':
        return 'upload/avatar';
      default:
        throw new Error('Unknown upload resource: ' + resource);
    }
  }

  static getSignedUrl(resource, options = {}) {
    return new Promise(async (resolve) => {
      const token = window.localStorage.getItem('zipline_id_token');
      const xhr = new XMLHttpRequest();
      const endpoint = this.getUploadEndpoint(resource);

      xhr.open('POST', `${process.env.REACT_APP_API_URL}/${endpoint}`);
      xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
      xhr.send(JSON.stringify({ contentType: options.contentType || 'image/jpeg' }));

      xhr.addEventListener('load', () => {
        resolve(JSON.parse(xhr.responseText));
      });
    });
  }

  static uploadToS3(url, file, onProgress) {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
  
      xhr.open('PUT', url);
      xhr.setRequestHeader('X-Amz-ACL', 'public-read');
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.setRequestHeader('Cache-Control', 'max-age=2592000');
      xhr.send(file);
  
      xhr.upload.addEventListener('progress', onProgress);
  
      xhr.addEventListener('load', () => {
        resolve();
      });
    });
  }

  static getImageDimensions(imageUrl) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageUrl;
      image.onload = () => resolve({ width: image.width, height: image.height });
      image.onerror = (err) => reject(err);
    });
  }
}

export default UploadHelper;
