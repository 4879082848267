import gql from 'graphql-tag';

export const CreateUsersMutation = gql`
  mutation CreateUsers (
    $users: [AdminUserInput]!
    $isEmailSent: Boolean!
  ) {
    adminCreateUsers(
      users: $users
      isEmailSent: $isEmailSent
    )
  }
`;
