import { compose, withStateHandlers, withHandlers, withProps } from 'recompose';

import csv from 'papaparse';
import UploadHelper from '../../helpers/upload';

import { graphql } from 'react-apollo';
import { CreateVideo } from './mutations';

import CreateVideos from './CreateVideos';

export default compose(
  graphql(
    CreateVideo,
    { name: 'createVideo' }
  ),

  withStateHandlers(
    {
      headers: [],
      videos: [],
      loading: false
    },
    {
      onHeadersChange: () => ({ headers }) => ({ headers }),
      onVideosChange: () => ({ videos }) => ({ videos }),
      onLoadingChange: () => ({ loading }) => ({ loading }),
      onUploadEnd: () => () => ({ isUploading: false, isToastVisible: true }),
      onHideToast: () => () => ({ isToastVisible: false })
    }
  ),

  withHandlers({
    onVideoChange: ({
      onVideosChange,
      videos
    }) => async ({ e, index }) => {
      const newVideos = videos;
      const file = e.target.files[0];

      newVideos[index] = Object.assign(
        {},
        newVideos[index],
        { video: { loading: true } }
      );

      onVideosChange({ videos: newVideos });
      
      const { url, signedUrl } = await UploadHelper.getSignedUrl(UploadHelper.VIDEO, {
        contentType: file.type
      });

      await UploadHelper.uploadToS3(signedUrl, file, () => {});

      const splitUrl = url.split('/');
      const wowzaId = splitUrl[splitUrl.length - 1];
      console.log(wowzaId);
      const name = file.name.split('.').slice(0, -1).join('.');

      newVideos[index] = Object.assign(
        {},
        newVideos[index],
        { video: { loading: false, wowzaId, name, url } }
      );

      onVideosChange({ videos: newVideos });
    },

    onVideoRemove: ({
      videos,
      onVideosChange
    }) => ({ index }) => {
      const newVideos = videos;
      delete newVideos[index].video;
      onVideosChange({ videos: newVideos });
    },

    onCSVChange: ({ onVideosChange, onHeadersChange }) => async (e) => {
      const files = Array.from(e.target.files);
      if (files.length) {
        csv.parse(files[0], {
          complete: result => {
            const videos = [];
            let headers;

            result.data.forEach((line, i) => {
              if (i === 0) {
                headers = line;
                
                if (!headers.includes('video')) {
                  headers.push('video');
                }

                onHeadersChange({ headers });
                return;
              }
              
              const row = {};

              headers.forEach((header, index) => {
                row[header] = line[index];
              });
              row.video = null;
              
              videos.push(row);
            });

            if (
              !headers.includes('name') ||
              !headers.includes('product') ||
              !headers.includes('owner')
            ) {
              alert('Missing required fields. See console for more details.');
              console.log('Required headers: name, product, owner');
              console.log(`Headers provided: ${JSON.stringify(headers)}`);
            }

            onVideosChange({ videos });
          },

          error: err => {
            console.log(err);
            alert(err);
          },

          delimiter: ','
        });
      }
    },

    onSubmit: ({
      videos,
      createVideo,
      onLoadingChange,
      onVideosChange,
      onHeadersChange
    }) => async () => {
      onLoadingChange({ loading: true });

      try {
        for (let i = 0; i < videos.length; i += 1) {
          const video = videos[i];
          await createVideo({
            variables: {
              video: {
                wowzaId: video.video.wowzaId,
                name: video.name,
                product: video.product,
                owner: video.owner
              }
            }
          });
        }

        onVideosChange({ videos: [] });
        onHeadersChange({ headers: [] });
        onLoadingChange({ loading: false });
        alert('Import successful');
      } catch(err) {
        console.log(err);
        alert(err);
      }
    }

  }),

  withProps(props => {
    for (let i = 0; i < props.videos.length; i += 1) {
      if (
        !props.videos[i].video ||
        props.videos[i].video.loading
      ) {
        return {
          isSubmitEnabled: false
        };
      }
    }

    return {
      isSubmitEnabled: true
    };
  })
)(CreateVideos);
