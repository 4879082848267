import React, { PureComponent } from 'react';
import { Paper, Typography, CircularProgress, Button } from '@material-ui/core';
import { CategoryList } from './CategoryList';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import './Categories.css';

class Categories extends PureComponent {
  render() {
    if (this.props.data.loading) {
      return (
        <Paper className="categories">
          <div className="content">
            <CircularProgress />
          </div>
        </Paper>
      );
    }

    return (
      <Paper className="categories">
        <div className="content">
          <div className="header">
            <Typography variant="h5">Categories</Typography>
            <Button color="primary" variant="outlined" onClick={this.props.onCreate}>Add</Button>
          </div>

          <CategoryList
            items={this.props.categories}
            onSortEnd={this.props.onSortEnd} 
            onUserSelectableChange={this.props.onUserSelectableChange}
            onActiveChange={this.props.onActiveChange}
            onNameChange={this.props.onNameChange}
          />

          <div className="info">
            <InfoIcon style={{ color: '#FFF', marginRight: 8 }} />
            <Typography variant="caption">
              To have a category removed, please contact{' '}
              <a href="mailto:development@zipline.co">Product Development</a>.
            </Typography>
          </div>
        </div>
      </Paper>
    );
  }
}

export default Categories;
