import React, { PureComponent } from 'react';

import {
  Paper,
  CircularProgress,
  Typography,
  InputBase,
  Checkbox
} from '@material-ui/core';
import {
  Search as SearchIcon
} from '@material-ui/icons';

import {
  VideoContainer as Video
} from './Video';

import { withStyles } from '@material-ui/core/styles';
import './Videos.css';

class Videos extends PureComponent {
  render() {
    const { loading, error, videos, refetch, classes, search, featured } = this.props;

    if (loading && search === '' && !featured) {
      return <CircularProgress />;
    }

    if (error) {
      return (
        <div className="error">
          <Typography>{JSON.stringify(error, null, 2)}</Typography>
        </div>
      );
    }

    return (
      <div className="videos">
        <Paper classes={{ root: classes.paper }}>
          <InputBase
            classes={{ root: classes.input }}
            placeholder="Search..."
            onChange={this.props.onSearchChange}
          />
          <SearchIcon classes={{ root: classes.icon }} />
        </Paper>
        <Paper classes={{ root: classes.filterPaper }}>
          <div className="filter">
            <Checkbox onChange={this.props.onFeaturedChange} color="primary" />
            <Typography>Show Featured</Typography>
          </div>
        </Paper>
        {
          !this.props.search.length && !this.props.featured &&
          <Typography variant="h5" className="header">Recent Videos</Typography>
        }
        {
          this.props.featured &&
          <Typography variant="h5" className="header">Featured Videos</Typography>
        }
        {
          videos.map(video => (
            <Video
              key={video.id}
              refetch={refetch}
              video={video}
              onFeaturedPress={this.props.onFeaturedPress}
            />
          ))
        }
      </div>
    );
  }
}

const styles = {
  paper: {
    padding: '10px 15px',
    marginBottom: 20,
    alignItems: 'center',
    width: '100%',
    display: 'flex',
    maxWidth: 370
  },

  subheading: {
    marginLeft: 15,
    marginTop: 10
  },

  filterPaper: {
    marginBottom: 20,
    alignItems: 'flex-start',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400
  },

  input: {
    display: 'flex',
    flex: 1
  },

  icon: {
    color: '#fff'
  }
};

export default withStyles(styles)(Videos);
