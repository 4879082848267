import React, { Component } from 'react';

import { Button } from '@material-ui/core';
import { Table } from '../../components/Table';

import './CreateVariants.css';

class CreateVariants extends Component {
  render() {
    return (
      <div className="create-products">
        {
          !this.props.variants.length
            ? (
              <Button
                variant="contained"
                component="label"
              >
                Upload CSV
                <input
                  type="file"
                  accept="text/csv"
                  style={{ display: 'none' }}
                  onChange={this.props.onCSVChange}
                />
              </Button>
            )
            : (
              <Table
                headers={this.props.headers}
                rows={this.props.variants}
                isSubmitEnabled
                onSubmit={this.props.onSubmit}
                loading={this.props.loading}
              />
            )
        }
      </div>
    );
  }
}

export default CreateVariants;
