import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import mixpanel from 'mixpanel-browser';
import MixpanelProvider from 'react-mixpanel';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#424452',
      paper: '#5b5d6c'
    },
    primary: {
      main: '#21aba4',
      contrastText: '#424452'
    },
    secondary: {
      main: '#333542',
      contrastText: '#FFF'
    },
    custom: {
      'main': '#5fcc9c',
      contrastText: '#424452'
    },
    text: {
      disabled: '#5b5d6c',
      primary: '#FFF',
      secondary: '#21aba4'
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Avenir',
      'Nunito',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
});

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
mixpanel.register({ '$ignore': process.env.NODE_ENV !== 'production' });

ReactDOM.render(
  <MixpanelProvider mixpanel={mixpanel}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </MixpanelProvider>,
  document.getElementById('root')
);
