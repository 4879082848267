import { compose } from 'recompose';
import { connect } from 'react-redux';
import { logout } from '../actions/account';

import AppRouter from './AppRouter';

export default compose(
  connect(
    (state) => ({ account: state.account }),
    (dispatch) => ({
      onLogout: () => (dispatch(logout()))
    })
  )
)(AppRouter);
