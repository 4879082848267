import { compose, withStateHandlers, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import Login from './Login';
import { loginWithCredentials } from '../../actions/account';

export default compose(
  withStateHandlers(
    () => ({
      username: '',
      password: '',
      isErrorVisible: false,
      error: ''
    }),
    {
      onUsernameChange: () => (e) => ({ username: e.target.value }),
      onPasswordChange: () => (e) => ({ password: e.target.value }),
      onErrorShow: () => () => ({ isErrorVisible: true }),
      onErrorHide: () => () => ({ isErrorVisible: false }),
      onError: () => (error) => ({ error })
    }
  ),

  connect(
    null,
    (dispatch) => ({
      login: async ({ username, password }) => {
        try {
          await dispatch(loginWithCredentials({ username, password }));
        } catch (err) {
          throw err;
        }
      }
    })
  ),

  withHandlers({
    onSubmit: ({ login, onError, onErrorShow }) => async ({ username, password }) => {
      try {
        await login({ username, password });
      } catch (err) {
        onError(err.message);
        onErrorShow();
      }
    }
  })
)(Login);
