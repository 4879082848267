import { compose, withStateHandlers, withProps } from 'recompose';

import { graphql } from 'react-apollo';
import { GetAccounts } from './queries';

import Users from './Users';

export default compose(
  withStateHandlers(
    {
      search: ''
    },
    ({
      onSearchChange: () => (e) => ({ search: e.target.value })
    })
  ),

  graphql(GetAccounts, {
    options: props => ({
      variables: {
        account: {
          createdAt: { orderBy: 'Descending' },
          name: { contains: props.search },
          email: { contains: props.search },
          username: { contains: props.search },
          limit: 10
        }
      }
    })
  }),

  withProps((props) => {
    return {
      loading: props.data.loading,
      error: props.data.error,
      users: (
        props.data.loading ||
        props.data.error
      ) ? [] : props.data.admin.accounts
    };
  })
)(Users);
